<template>
  <b-card header="Send Message">
    <validation-observer #default="{handleSubmit}">
      <b-form @submit.prevent>
        <validation-provider
          #default="validationContext"
          name="Title"
          rules="required"
        >
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-textarea
              id="title"
              v-model="message.title"
              placeholder="Title"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="validationContext"
          name="Message"
          rules="required"
        >
          <b-form-group
            label="Message"
            label-for="message"
          >
            <b-form-textarea
              id="message"
              v-model="message.body"
              placeholder="Message"
              :state="getValidationState(validationContext)"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
        <submit-button
          :handle-submit="handleSubmit"
          :submit="sendMessage"
          submit-text="Send"
        />
        <back />
      </b-form>
    </validation-observer>
  </b-card>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import Back from '@/common/components/common/FormInputs/Back.vue'

export default {
  name: 'SendWinningMessage',
  components: {
    SubmitButton,
    Back,
  },
  data() {
    return {
      message: {},
    }
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()

    const {
      successfulOperationAlert,

    } = handleAlerts()

    return { getValidationState, successfulOperationAlert }
  },
  methods: {
    sendMessage() {
      return this.$activities.post('/internalops/live-winners/send-message', {
        title: this.message.title,
        body: this.message.body,
        users_id: JSON.parse(this.$route.params.usersId),
      }).then(() => {
        this.successfulOperationAlert('Message is sent successfully')
        this.$router.go(-1)
      })
    },
  },
}
</script>
<style lang="">

</style>
